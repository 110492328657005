<template>
  <div ref="parent" class="md-power">
    <a-modal
      centered
      width="30%"
      title="设置数据权限"
      :visible="visible"
      @cancel="handleCancel"
      :getContainer="() => $refs.parent"
    >
      <span>请选择权限范围</span>
      <TreeZj v-if="treeData.length" :dataList="treeData" :checkData="selectID" @selectedKeys="selectedKeys" />
      <div v-else class="department-list" style="line-height: 204px;text-align: center;padding:0">暂无数据</div>
      <template #footer>
        <a-button @click="handleCancel" class="MyCancel">取消</a-button>
        <a-button class="comfirm" @click="comfirmAdd">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, toRaw, watch, computed, onMounted } from 'vue'
import TreeZj from './tree'
export default defineComponent({
  components: { TreeZj },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    organizationalList: {
      type: Array,
      default: () => []
    },
    selectID: {
      type: Array,
      default: () => []
    }
  },
  setup(props, ctx) {
    const { emit } = ctx
    const state = reactive({
      department: [],
      treeData: [],
      departmentNames: '',
      dataScope: 0
    })
    const handleCancel = () => {
      emit('update:visible', false)
    }
    const comfirmAdd = () => {
      emit('update:visible', false)
      emit('selectedDepartment', state.department, state.departmentNames, state.dataScope)
    }

    const selectedKeys = (value, name, allIn) => {
      state.department = value
      state.departmentNames = name
      state.dataScope = allIn
    }

    watch(
      () => props.organizationalList.length,
      newValue => {
        if (newValue > 0) {
          state.treeData = props.organizationalList
          // state.treeData = [
          //   {
          //     departmentDTOList: props.organizationalList,
          //     departmentName: '全部部门',
          //     id: 'all'
          //   }
          // ]
        }
      },
      { immediate: true }
    )
    return {
      ...toRefs(state),
      handleCancel,
      comfirmAdd,
      selectedKeys
    }
  }
})
</script>

<style lang="scss" scoped>
.comfirm {
  background: #c3161c;
  color: #fff;
  border: 1px solid transparent;
  margin-right: 0;
  &:hover {
    border-color: transparent;
  }
}
.md-power {
  :deep(.ant-tree) {
    .ant-tree-title {
      display: inline-block;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>
